import React, { useState } from 'react';
import { CFade } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import TimezoneSelect from 'react-timezone-select';
import {
  Button, Flex, Form, Input, Switch,
} from 'antd';
import classnames from 'classnames';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useForm from '../../hooks/useForm';
import useApiClient from '../../hooks/useApiClient';
import { SUPPORTED_COUNTRIES } from '../../services/exports/Constants';

function AddressFields({
  title,
  prefix,
  data,
  onChange,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:BusinessInfoForm' });

  return (
    <div className={className}>
      {Boolean(title) && <h6 className="tw-underline tw-mt-small">{title}</h6>}
      <Form.Item className="tw-mt-small" label={t(`form.${prefix}_country.label`)} required>
        <CountryDropdown
          value={data[`${prefix}_country`]}
          whitelist={SUPPORTED_COUNTRIES}
          valueType="short"
          onChange={(value) => onChange(`${prefix}_country`, value)}
          defaultOptionLabel={t(`form.${prefix}_country.placeholder`)}
          classes="form-control"
        />
      </Form.Item>
      <Form.Item label={t(`form.${prefix}_state.label`)} required>
        <RegionDropdown
          country={data[`${prefix}_country`]}
          value={data[`${prefix}_state`]}
          countryValueType="short"
          onChange={(value) => onChange(`${prefix}_state`, value)}
          defaultOptionLabel={t(`form.${prefix}_state.placeholder`)}
          classes="form-control"
        />
      </Form.Item>
      <Form.Item label={t(`form.${prefix}_city.label`)} required>
        <Input
          placeholder={t(`form.${prefix}_city.placeholder`)}
          value={data[`${prefix}_city`] ?? ''}
          onChange={(e) => onChange(`${prefix}_city`, e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t(`form.${prefix}_address.label`)} required>
        <Input
          placeholder={t(`form.${prefix}_address.placeholder`)}
          value={data[`${prefix}_address`] ?? ''}
          onChange={(e) => onChange(`${prefix}_address`, e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t(`form.${prefix}_zip_code.label`)} required>
        <Input
          placeholder={t(`form.${prefix}_zip_code.placeholder`)}
          value={data[`${prefix}_zip_code`] ?? ''}
          onChange={(e) => onChange(`${prefix}_zip_code`, e.target.value)}
        />
      </Form.Item>
    </div>
  );
}

export default function BusinessInfoForm(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:BusinessInfoForm' });
  const { skip, buttonText, moveForward } = props;

  const { company } = useSelector((store) => store.currentCompany);
  const { merchant } = company;

  const { MerchantProfileManager } = useApiClient();

  const { data, update } = useForm({
    legal_name: merchant?.legal_name,
    timezone: company?.timezone,
    business_country: merchant?.business_country,
    business_state: merchant?.business_state,
    business_city: merchant?.business_city,
    business_address: merchant?.business_address,
    business_zip_code: merchant?.business_zip_code,

    has_dedicated_invoice_address: merchant?.has_dedicated_invoice_address,
    invoice_country: merchant?.invoice_country,
    invoice_state: merchant?.invoice_state,
    invoice_city: merchant?.invoice_city,
    invoice_address: merchant?.invoice_address,
    invoice_zip_code: merchant?.invoice_zip_code,
  });

  const [loading, setLoading] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  async function save() {
    setLoading(true);
    const { success, data: responseData } = await MerchantProfileManager.update(data);
    setLoading(false);

    if (!success) {
      return setFeedback({
        type: 'error',
        message: responseData?.message,
        errors: responseData?.errors,
      });
    }

    return moveForward && moveForward();
  }

  return (
    <CFade>
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item
          label={t('form.legal_name.label')}
          tooltip={t('form.legal_name.tooltip')}
          required
        >
          <Input
            placeholder={t('form.legal_name.placeholder')}
            value={data.legal_name ?? ''}
            onChange={(e) => update('legal_name', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.timezone.label')} required>
          <TimezoneSelect
            value={data.timezone}
            onChange={(value) => update('timezone', value.value)}
          />
        </Form.Item>
        <hr />
        <Flex justify="space-between" gap={16}>
          <h6 className="tw-my-auto">{t('sections.address')}</h6>
          <Flex>
            <label htmlFor="has_dedicated_invoice_address" className="tw-mr-small tw-my-auto">
              {t('form.has_dedicated_invoice_address.label')}
            </label>
            <Switch
              checked={data.has_dedicated_invoice_address}
              onChange={(checked) => update('has_dedicated_invoice_address', checked)}
              className="tw-my-auto"
              id="has_dedicated_invoice_address"
            />
          </Flex>
        </Flex>
        <div className="tw-grid tw-grid-cols-2 md:tw-gap-10 tw-relative">
          <AddressFields
            title={data.has_dedicated_invoice_address ? t('sections.business_address') : null}
            prefix="business"
            data={data}
            onChange={update}
            className={classnames('tw-col-span-2', { 'md:tw-col-span-1': data.has_dedicated_invoice_address })}
          />
          {data.has_dedicated_invoice_address && (
            <>
              <div className="md:tw-block tw-hidden tw-absolute tw-h-[90%] tw-border-[1px] tw-border-r-none tw-border-[#0E0E0E33] tw-border-solid tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" />
              <AddressFields
                title={t('sections.invoice_address')}
                prefix="invoice"
                data={data}
                onChange={update}
                className="tw-col-span-2 md:tw-col-span-1"
              />
            </>
          )}
        </div>
      </Form>
      <Flex justify="end">
        <Button
          type="primary"
          onClick={save}
          loading={loading}
        >
          {buttonText ?? t('buttons.next')}
        </Button>
      </Flex>
    </CFade>
  );
}
