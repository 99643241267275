export default {
  welcome: 'Welcome',
  we_miss_you: 'We miss you',
  happy_friday: 'Happy Friday',
  lazy_sunday: 'Lazy Sunday',
  loyalty_program: 'Loaylty Program',
  you_might_also_like: 'You might also like',
  storefront_promo: 'Storefront Promo',
  welcome_members_club: 'Welcome members club',
};
