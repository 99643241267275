export default {
  title: 'Einstellungen',
  fields: {
    discount_type: {
      label: 'Rabatt Art',
    },
  },
  buttons: {
    save: 'Speichern',
    cancel: 'Abbrechen',
  },
  toasts: {
    update_successful: 'Erfolgreich aktualisiert',
    updated_failed: 'Aktualisieren fehlgeschlagen',
  },
};
