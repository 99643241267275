export default {
  sections: {
    address: 'Address',
    business_address: 'Registration address',
    invoice_address: 'Invoice address',
  },
  form: {
    legal_name: {
      label: 'Legal name',
      placeholder: 'Enter legal name',
      tooltip: 'Registration name. Displayed on payout reports, contract, etc',
    },
    timezone: {
      label: 'Timezone',
    },
    has_dedicated_invoice_address: {
      label: 'Different billing info',
    },
    business_country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    business_state: {
      label: 'State',
      placeholder: 'Select state',
    },
    business_city: {
      label: 'Restaurant city',
      placeholder: 'Enter restaurant city',
    },
    business_address: {
      label: 'Restaurant address',
      placeholder: 'Street + Number',
    },
    business_zip_code: {
      label: 'Restaurant postal code',
      placeholder: 'Enter postal code',
    },
    invoice_country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    invoice_state: {
      label: 'State',
      placeholder: 'Select state',
    },
    invoice_city: {
      label: 'City',
      placeholder: 'Enter city',
    },
    invoice_address: {
      label: 'Address',
      placeholder: 'Street + Number',
    },
    invoice_zip_code: {
      label: 'Postal code',
      placeholder: 'Enter postal code',
    },
  },
  buttons: {
    next: 'Next',
  },
};
