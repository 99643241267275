export default {
  fields: {
    code: 'Code',
    tag: 'Tag',
    type: 'Type',
    discount_type: 'Discount type',
    discount: 'Discount',
    usage_threshold: 'Usage threshold',
    quantity: 'Quantity',
    expires_at: 'Expires',
    customers: 'Customers',
    purchases: 'Orders',
    sales: 'Sales',
    level: 'Level',
    max_discount: 'Max discount',
  },
  tags: {
    master: 'Master',
    campaign: 'Campaign',
    one_time: 'One Time',
  },
  types: {
    single_use: 'Single use',
    multi_use: 'Multi use',
    unknown: 'Unknown',
  },
  discount_types: {
    fixed_amount: 'Fixed amount',
    percentage: 'Percentage',
    unknown: 'Unknown',
  },
  labels: {
    discount: 'Discount',
    usage_threshold: 'Usage threshold',
    company: 'Company',
  },
  collecting_emails: {
    description: 'Here, you can configure the one-time promo code generated for new users upon signing up on the website.',
  },
  buttons: {
    edit: 'Edit',
  },
  toasts: {
    successfully_deleted: 'Successfully deleted promo code settings',
    failed_to_load_data: 'Failed to load data',
  },
};
