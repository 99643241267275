export default {
  title: 'Terminal',
  stats: {
    terminal_battery_level: 'Akkustand',
    terminal_battery_state: 'Ladestatus',
    auto_accept_orders: 'Bestellungen automatisch annehmen',
  },
  auto_accept_orders_modal: {
    title: 'Sind Sie sicher, dass Sie Bestellungen automatisch annehmen möchten?',
    buttons: {
      activate: 'Aktivieren',
      cancel: 'Abbrechen',
    },
  },
  feedback: {
    update_success: 'Erfolgreich aktualisiert',
    update_failed: 'Fehler beim Aktualisieren der Informationen. Bitte versuchen Sie es erneut.',
  },
};
