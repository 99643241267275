import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import { useMemo } from 'react';
import { PERMISSIONS, ROLES } from '../services/exports/Constants';

export default function usePermissions() {
  const { profile, user_access_control_list } = useSelector((state) => state.initialData);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const roles = profile?.roles ?? [];
  const isAdmin = profile?.is_admin === true;
  const isReseller = isAdmin || roles.includes(ROLES.reseller);
  const isSalesManager = isAdmin || roles.includes(ROLES.sales_manager);
  const isContentManager = isAdmin || roles.includes(ROLES.content_manager);
  const isOnboardingManager = isAdmin || roles.includes(ROLES.onboarding_manager);
  const isMerchant = !isAdmin && !isReseller && !isSalesManager && !isContentManager && !isOnboardingManager;

  const accessControlList = useMemo(
    () => {
      const list = user_access_control_list ?? [];

      if (Array.isArray(list) && list.includes('*')) {
        return list;
      }

      const providerList = list[serviceProvider?.id] ?? [];

      return collect(providerList).values().toArray();
    },
    [user_access_control_list, serviceProvider?.id],
  );

  const permissions = {
    [ROLES.reseller]: [
      PERMISSIONS.manage_payouts,
      PERMISSIONS.enable_daily_payouts,

      PERMISSIONS.delete_menu,
      PERMISSIONS.edit_menu_properties,

      PERMISSIONS.put_company_live,
      PERMISSIONS.change_merchant_email,
      PERMISSIONS.mark_merchant_email_as_verified,
      PERMISSIONS.convert_loyalty_program_to_cashback,
      PERMISSIONS.edit_subscription_prices,
      PERMISSIONS.manage_external_delivery,
      PERMISSIONS.access_stripe_dashboard,
      PERMISSIONS.edit_company_access_control_list,
      PERMISSIONS.create_internal_invoice_items,
      PERMISSIONS.create_master_promo_codes,

      PERMISSIONS.dashboard_view_restaurant_details_tab,
      PERMISSIONS.dashboard_view_external_delivery_tab,
    ],
    [ROLES.sales_manager]: [
      PERMISSIONS.manage_payouts,
      PERMISSIONS.put_company_live,
      PERMISSIONS.mark_merchant_email_as_verified,
      PERMISSIONS.edit_subscription_prices,
      PERMISSIONS.manage_external_delivery,
      PERMISSIONS.create_master_promo_codes,
    ],
    [ROLES.onboarding_manager]: [
      PERMISSIONS.delete_menu,
      PERMISSIONS.edit_menu_properties,

      PERMISSIONS.put_company_live,
      PERMISSIONS.change_merchant_email,
      PERMISSIONS.mark_merchant_email_as_verified,
      PERMISSIONS.convert_loyalty_program_to_cashback,
      PERMISSIONS.manage_external_delivery,
      PERMISSIONS.edit_company_access_control_list,
      PERMISSIONS.create_master_promo_codes,
    ],
  };

  const hasAnyRole = (_roles) => {
    if (isAdmin) {
      return true;
    }

    return collect(_roles)
      .intersect(roles)
      .isNotEmpty();
  };

  const isRole = (role) => roles && roles.length === 1 && roles[0] === role;

  const can = (permission) => {
    if (isAdmin) {
      return true;
    }

    return collect(permissions)
      .filter(
        (item, role) => roles.includes(role),
      )
      .flatten()
      .intersect(collect(permission))
      .isNotEmpty();
  };

  const cant = (permission) => !can(permission);

  return {
    roles,
    isAdmin,
    isMerchant,
    isReseller,
    isSalesManager,
    isOnboardingManager,
    accessControlList,
    can,
    cant,
    hasAnyRole,
    isRole,
  };
}
