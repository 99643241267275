import TemplateNames from "../email-messages/TemplateNames";
import MarketingSubscriptionSources from "../followers/MarketingSubscriptionSources";

export default {
  title: "Marketing overview",
  tiles: {
    followers: "Followers",
    delivered_messages: "Delivered messages",
    open_rate: "Open rate",
    click_rate: "Click rate",
    spam_report_rate: "Spam report rate",
    unsubscribe_rate: "Unsubscribe rate",
  },
  subscriprion_sources_table: {
    source_names: MarketingSubscriptionSources,
    columns: {
      subscription_source: "Subscription Source",
      count: "Count",
      percentage: "Percentage",
    }
  },
  templates_table: {
    template_names: TemplateNames,
    columns: {
      template: "Template",
      delivered: "Delivered",
      open_rate: "Open rate",
      click_rate: "Click rate",
      spam_report_rate: "Spam report rate",
      unsubscribe_rate: "Unsubscribe rate",
    }
  },
  labels: {
    no_data: "No data",
  },
  toasts: {
    failed_to_load_data: "Failed to load data",
  },
};
