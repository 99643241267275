import ApiResourceManager from '../ApiResourceManager';

export default class MarketingSettingsManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/marketing-promo-code-settings',
  });

  post = (data) => this.request({
    method: 'POST',
    url: '/marketing-promo-code-settings',
    data,
  });

  delete = () => this.request({
    method: 'DELETE',
    url: '/marketing-promo-code-settings',
  });
}
