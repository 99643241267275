import React from 'react';
import {
  Button,
  Col, Row, Switch, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

export default function LiveOrderErrorRow({
  error, setSkippedChecks,
}) {
  const { Text } = Typography;
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Modals:OperationalStatus' });

  const skippedChecksHandler = () => {
    setSkippedChecks((prev) => {
      if (prev.includes(error.key)) {
        return prev.filter((item) => item !== error.key);
      }

      return [...prev, error.key];
    });
  };

  const navigateInNewTab = (url) => {
    const fullUrl = `${window.location.origin}${url}`;
    window.open(fullUrl, '_blank');
  };

  return (
    <Row justify="space-between" align="middle" className="mb-3">
      <Col>
        <div>
          <Text strong>{error.title}</Text>
        </div>
        <div className="mb-1">
          <Text type="secondary">{error.description}</Text>
        </div>
        <div>
          <Button onClick={() => { navigateInNewTab(error.url); }}>{t('buttons.check_error')}</Button>
        </div>
      </Col>
      <Col>
        <Switch onChange={skippedChecksHandler} />
      </Col>
    </Row>
  );
}
