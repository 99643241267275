export default {
  welcome: 'Willkommen',
  we_miss_you: 'Wir vermissen dich',
  happy_friday: 'Happy Friday',
  lazy_sunday: 'Lazy Sunday',
  loyalty_program: 'Treueprogramm',
  you_might_also_like: 'Dir könnte auch gefallen',
  storefront_promo: 'Storefront Promo',
  welcome_members_club: 'Willkommen im Mitgliederclub',
};
