export default {
  title: 'Terminal',
  stats: {
    terminal_battery_level: 'Battery level',
    terminal_battery_state: 'Charging status',
    auto_accept_orders: 'Automatically accept orders',
  },
  auto_accept_orders_modal: {
    title: 'Are you sure you want to auto accept orders?',
    buttons: {
      activate: 'Activate',
      cancel: 'Cancel',
    },
  },
  feedback: {
    update_success: 'Successfully updated',
    update_failed: 'Failed to update information. Please try again.',
  },
};
