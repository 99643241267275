export default {
  title: 'Settings',
  fields: {
    discount_type: {
      label: 'Discount type',
    },
  },
  buttons: {
    save: 'Save',
    cancel: 'Cancel',
  },
  toasts: {
    update_successful: 'Updated successfully',
    updated_failed: 'Update failed',
  },
};
