export default {
  fields: {
    code: 'Code',
    tag: 'Tag',
    type: 'Typ',
    discount_type: 'Rabattart',
    discount: 'Rabatt',
    usage_threshold: 'Nutzungsschwelle',
    quantity: 'Menge',
    expires_at: 'Ablaufdatum',
    customers: 'Kunden',
    purchases: 'Bestellungen',
    sales: 'Verkäufe',
    level: 'Stufe',
    max_discount: 'Maximaler Rabatt',
  },
  tags: {
    master: 'Hauptcode',
    campaign: 'Kampagne',
    one_time: 'Einmalig',
  },
  types: {
    single_use: 'Einmalige Nutzung',
    multi_use: 'Mehrfache Nutzung',
    unknown: 'Unbekannt',
  },
  discount_types: {
    fixed_amount: 'Fester Betrag',
    percentage: 'Prozentsatz',
    unknown: 'Unbekannt',
  },
  labels: {
    discount: 'Rabatt',
    usage_threshold: 'Nutzungsschwelle',
    company: 'Unternehmen',
  },
  collecting_emails: {
    description: 'Hier können Sie den einmaligen Gutscheincode konfigurieren, der neuen Benutzern beim Registrieren auf der Website generiert wird.',
  },
  buttons: {
    edit: 'Bearbeiten',
  },
  toasts: {
    successfully_deleted: 'Gutscheincode-Einstellungen erfolgreich gelöscht',
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
