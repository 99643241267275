import TemplateNames from "../email-messages/TemplateNames";
import MarketingSubscriptionSources from "../followers/MarketingSubscriptionSources";

export default {
  title: "Marketing-Übersicht",
  tiles: {
    followers: "Follower",
    delivered_messages: "Gesendete Nachrichten",
    open_rate: "Öffnungsrate",
    click_rate: "Klickrate",
    spam_report_rate: "Spam-Report-Rate",
    unsubscribe_rate: "Abmeldequote",
  },
  subscriprion_sources_table: {
    source_names: MarketingSubscriptionSources,
    columns: {
      subscription_source: "Abonnementquelle",
      count: "Anzahl",
      percentage: "Prozentsatz",
    }
  },
  templates_table: {
    template_names: TemplateNames,
    columns: {
      template: "Vorlage",
      delivered: "Geliefert",
      open_rate: "Öffnungsrate",
      click_rate: "Klickrate",
      spam_report_rate: "Spam-Report-Rate",
      unsubscribe_rate: "Abmeldequote",
    }
  },
  labels: {
    no_data: "Keine Daten",
  },
  toasts: {
    failed_to_load_data: "Daten konnten nicht geladen werden",
  },
};
